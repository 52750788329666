<template>
  <section>
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.applyDate') }}</span></div>
           <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs"  @setOldYn="setOldYn" :oldYn="reqData.oldYn"/>
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.sch') }}</span></div>
           <input type="text" v-model="reqData.siteId" :placeholder="$t('table.head.companyId')" />
         </div>
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{$t('table.head.userID')}}</span></div>
           <div>
             <input type="text" class="mr-5" v-model="reqData.memId" :placeholder="$t('table.head.userID')" />
             <button class="btn-search" type="button" @click="pageSeach">
               <i class="fas fa-search"></i>
             </button>
           </div>
         </div>

      </div>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container fx-col-sbw">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <!-- <table-head :headInfo="headInfo" /> -->
          <thead>
            <tr>
              <th rowspan="2">{{ $t('table.head.idx') }}</th>
              <th rowspan="2">{{ $t('table.head.company') }}</th>
              <th rowspan="2">{{ $t('table.head.userID') }}</th>
              <th rowspan="2">{{ $t('table.head.betDate') }}</th>
              <th rowspan="2">{{ $t('table.head.betAmt') }}</th>
              <th rowspan="2">{{ $t('table.head.wl') }}</th>
              <th rowspan="2">{{ $t('table.head.insureBetYN') }}</th>
              <th colspan="3">{{ $t('table.head.insureBet') }}</th>
              <th colspan="3">{{ $t('table.head.credit') }}</th>
              <th colspan="3">{{ $t('table.head.feeRate') }}</th>
              <th colspan="3">{{ $t('table.head.point') }}</th>
            </tr>
            <tr>
              <!-- 보험베팅 -->
              <th>{{ $t('table.head.insurBetApplyStd') }}</th>
              <th>{{ $t('table.head.insureBetAmt') }}</th>
              <th>{{ $t('table.head.insureRate') }}</th>
              <!-- 크레딧 -->
              <th>{{ $t('table.head.preBet') }}</th>
              <th>{{ $t('table.head.pm') }}</th>
              <th>{{ $t('table.head.aftBet') }}</th>
              <!-- 수수료 -->
              <th>{{ $t('table.head.std') }}</th>
              <th>{{ $t('table.head.game') }}</th>
              <th>{{ $t('table.head.applied') }}</th>
              <!-- 포인트 -->
              <th>{{ $t('table.head.preSave') }}</th>
              <th>{{ $t('table.head.collectPointAmt') }}</th>
              <th>{{ $t('table.head.aftSave') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="tableData.length!==0">
              <tr v-for="item of tableData" :key="item.idx">
                <td>{{item.idx}}</td>
                <td>
                  <button type="button" class="fc-id btn-link" @click="detailOpen('company',item.siteId)">{{item.siteName}}</button>
                </td>
                <td>{{item.memId}}</td>
                <td>{{item.betRegDt}}</td>

                <td>{{numberWithCommas (item.betAmt)}}</td>
                <td :class="Number(item.betWlAmt) < 0?'fc-red':'fc-blue'">{{numberWithCommas(item.betWlAmt)}}</td>

                <td>{{item.isBiBet}}</td>
                <td>{{numberWithCommas(item.baseInsureBetAmt)}}</td>
                <td>{{numberWithCommas (item.biBetAmt)}}</td>
                <td>{{item.insureRate?item.insureRate:'-'}}</td>

                <td>{{numberWithCommas(item.bfMyCredit)}}</td>
                <td :class="Number(item.myCreditAmt) < 0?'fc-red':'fc-blue'">{{numberWithCommas(item.myCreditAmt)}}</td>
                <td>{{numberWithCommas(Number(item.bfMyCredit)+Number(item.myCreditAmt))}}</td>

                <td>{{Number(item.creditRate).toFixed(2)}} %</td>
                <td>{{Number(item.pointRate).toFixed(2)}} %</td>
                <td :class="(Number(item.creditRate) - Number(item.pointRate))<0?'fc-red':'fc-blue'">{{Number(item.creditRate) - Number(item.pointRate)}} %</td>

                <td>{{numberWithCommas(item.preCreditSubPoint)}}</td>
                <td :class="Number(item.creditSubPointAmt) < 0?'fc-red':'fc-blue'">{{numberWithCommas(item.creditSubPointAmt)}}</td>
                <td>{{numberWithCommas(item.creditSubPoint)}}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="19">{{ $t('txt.noData') }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import DateSelector from '@/components/common/DateSelector'
import { getSiteData } from '@/libs/auth-helper'
import { companySubPointBotList } from '@/api/company.js'
import Pagination from '@/components/common/Pagination'
import lodash from 'lodash'

export default {
  name: 'CreditPointList',
  components: {
    DateSelector,
    Pagination
  },
  watch: {
    $route: {
      async handler () {
        const status = this.$route.params.status
        console.log(status)
        this.setTableData(1, status)
      }
    }
  },
  data: function () {
    return {
      reqData: {
        startDate: '',
        endDate: '',
        page: 1,
        count_per_list: '30',
        memId: '',
        siteId: '',
        oldYn: 'N'
      },
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      pageInfo: {},
      siteInfo: {},
      tableData: []
    }
  },
  methods: {
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    pageSeach () {
      this.setTableData(1)
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    async getSubPointList () {
      const req = lodash.cloneDeep(this.reqData)
      const res = await companySubPointBotList(req)
      console.log(res)
      res.resultCode = '0'
      if (res.resultCode === '0') {
        return res.data
      } else {
        return false
      }
    },
    async setTableData (pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum
      }
      const data = await this.getSubPointList()
      if (!data) {
        console.error('point list response error')
        return
      }
      this.tableData = data.list
      const pageInfo = data.pageInfo
      if (pageInfo) {
        this.pageInfo = this.setPageInfo(pageInfo)
      }
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = this.getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
        _date.setSeconds(59)
      }
      this.endDefault = _date
      this.reqData.endDate = this.getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()
    this.setStartDate()
    this.setEndDate()
    await this.setTableData()
  }
}
</script>

<style scoped>
.statusColor {
  font-weight: 700;
}
.statusColor.apply {
  color: #ef8c1d;
}
.statusColor.approve {
  color: blue;
}
.statusColor.refuse {
  color: red;
}
.statusColor.wait {
  color: green;
}
</style>
